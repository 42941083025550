import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAssets(ctx, queryParams) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Asset/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAsset(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetSummary(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset/summary?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        
        fetchAlarms(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/Asset/eventgenerator/search?AssetId=${id}&Page=1&PerPage=100`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addMoveAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/move', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAlarmWeekendMove(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/weekendmove', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addIgnitionAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/ignition', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addSevereAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/severeuse', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addIddleAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/iddleuse', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }, 
        addLongTimeAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/longtimewithoutconnection', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }, 
        addOverSpeedAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/overspeed', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addHighConsumptionAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/highfuelconsumption', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addLowFuelAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/lowfuel', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteAlarm(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/Asset/eventgenerator?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAlerts(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                var qs = ''
                if(queryParams.alertIds && queryParams.alertIds.length > 0){
                    queryParams.alertIds.map(id =>{
                        qs += `&EventTypes=${id}`
                    })
                    
                }

                if(!queryParams.ownerId) queryParams.ownerId = ''
                if(!queryParams.assetId) queryParams.assetId = ''
                
                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Event/search?Search=${queryParams.q}&OwnerId=${queryParams.ownerId}&AssetId=${queryParams.assetId}&Start=${queryParams.start}&End=${queryParams.end}&Page=${queryParams.page}&PerPage=10${qs}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTypeAlerts(){
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Event/searchtype`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
        

    },
}