<template>
<section class="invoice-preview-wrapper">

    <b-row class="invoice-preview">

        <!-- Col: Left (table container) -->
        <b-col cols="12" :md="colLeftMd" >
            <transition name="fade" mode="out-in">
                <b-card key="3" v-if="showFilters" no-body class="mb-2 ">
                    <b-card-header class="d-flex justify-content-between pl-1 pt-1">
                        <h5>
                            {{$t('Filters')}}
                        </h5>
                        <b-avatar @click="showFilters = false" v-b-tooltip.hover :title="titleTootipBtnCloseFilter" class="mr-1" button variant="danger" size="30px">
                            <feather-icon icon="XIcon" />
                        </b-avatar>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="6" class="mb-md-0 mb-2">
                                <h5 class="font-weight-bold">{{$t('Date Start')}}</h5>
                                <flat-pickr v-model="dateStart" class="form-control" />
                            </b-col>
                            <b-col cols="12" md="6" class="mb-md-0 mb-2">
                                <h5 class="font-weight-bold">{{$t('Date End')}}</h5>
                                <flat-pickr v-model="dateEnd" class="form-control" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-1">
                            <b-col cols="12" md="6" class="mb-md-0 mb-2">
                                <h5 class="font-weight-bold">Alarm</h5>
                                <multiselect v-model="chosenAlert" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="placeholderSelect" label="name" track-by="name" :preselect-first="false">
                                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} &nbsp; {{$t('options selected')}} </span></template>
                                </multiselect>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-button @click="fetchAlerts()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="float-left btn-icon mt-md-2">
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

            </transition>

            <b-card no-body class="invoice-preview-card">
                <table class="table ">
                    <thead>
                        <tr>
                            <th class="style-dark text-center" colspan="3" scope="col">

                                <span style="font-size: 0.9rem;" >
                                    🎫 {{ $t('Model') }}: <strong class="mr-2">{{ asset.modelIdName }}</strong> {{ $t('Identifier') }}: <strong>{{ asset.plate ? asset.plate : asset.chassisNumber }}</strong>
                                </span>
                                <span v-show="!showFilters" class="float-right ml-1">
                                    <b-avatar @click="showFilters = true" button rounded="sm" variant="primary" size="25px" v-b-tooltip.hover :title="titleTootipBtnFilter">
                                        <feather-icon icon="FilterIcon" />
                                    </b-avatar>
                                </span>

                                <!-- <span v-show="!showSettings" class="float-right ml-1">
                                    <b-avatar @click="showRightCol" button rounded="sm" variant="primary" size="25px" v-b-tooltip.hover :title="titleTootipBtnSettings">
                                        <feather-icon icon="SlidersIcon" />
                                    </b-avatar>
                                </span> -->

                            </th>
                        </tr>
                    </thead>

                    <tbody v-if="itens.length > 0">
                        <template v-for="item in itens">
                            <tr class="row px-1 ">
                                <td class="d-flex justify-content-between col-sm-12 day-table"><span class="font-weight-bold">{{ item.dayWeek }}</span> <span class="font-weight-bold">{{ item.date }}</span></td>
                            </tr>
                            <tr class="row px-1">
                                <template v-for="hour in item.hours">
                                    <td class=" col-sm-4">{{ formateDate(hour.datetime) }}</td>
                                    <td class=" col-sm-8 text-center">
                                        <b-badge  :variant="resolveVariant(hour.eventTypeId)">
                                            <span> {{hour.eventTypeName }}</span>
                                        </b-badge>
                                    </td>
                                </template>

                            </tr>
                        </template>

                    </tbody>
                    <tbody v-if="itens.length == 0">
                        <tr class="row px-1 ">
                            <td class="text-center col-sm-12 day-table">
                                <h5>{{ this.$t('No matching records found') }}</h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Section footer table -->
                <div v-show="itens.length > 0" class="m-2">
                    <b-row>

                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted"> {{ $t('Showing')}} {{ from }} {{ $t('to')}} {{to}} {{ $t('of')}} {{ totalItems }} {{ $t('entries')}} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                            <b-pagination v-model="currentPage" :total-rows="totalItems" per-page="10" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </b-card>
        </b-col>

        <!-- Right Col: (Alarm Settings) -->
        <b-col cols="12" md="5"  class="invoice-actions" v-show="showSettings">
            <b-card>

                <!-- Header -->
                <div class="pb-1 customizer-section d-flex justify-content-between align-items-center alarm-section">
                    <div>
                        <b-avatar @click="showRightCol" v-b-tooltip.hover class="mb-1 float-right" button variant="danger" size="30px">
                                <feather-icon icon="XIcon" />
                        </b-avatar>
                        <h4 class="text-uppercase mb-1">
                            {{$t('Alarms Settings')}}
                            
                        </h4>
                        
                        <p >🎫 {{ $t('Model') }}: <strong class="mr-2">{{ asset.modelIdName }}</strong> {{ $t('Identifier') }}: <strong>{{ asset.plate ? asset.plate : asset.chassisNumber }}</strong></p>

                        <small>{{$t('In this panel you can activate and deactivate different types of alarms for your vehicle.')}}</small>
                    </div>
                </div>
                <!-- Header -->

                <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area scroll-area mb-2">
                    <!-- Move -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipMove" icon="HelpCircleIcon" size="16" /> {{ $t('Move')}}
                        </span>
                        <b-form-checkbox id="check-move" v-model="alarmMove.val" @change="toogleAlarm(MOVE_ALARM_NAME)" name="alarm-move" class="mr-0" switch inline />
                    </div>

                    <!-- Weekend Move -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipWeekendMove" icon="HelpCircleIcon" size="16" /> {{ $t('Weekend Move')}}
                        </span>
                        <b-form-checkbox id="check-weekend-move" v-model="alarmWeekendMove.val" @change="toogleAlarm(WEEKEND_MOVE_ALARM_NAME)" name="alarm-weekendmove" class="mr-0" switch inline />
                    </div>

                    <!-- Ignition -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipIgnition" icon="HelpCircleIcon" size="16" /> {{ $t('Ignition')}}
                        </span>
                        <b-form-checkbox id="check-ignition" @change="toogleAlarm(IGNITION_ALARM_NAME)" v-model="alarmIgnition.val" name="alarm-ignition" class="mr-0" switch inline />
                    </div>

                    <!-- Severe Use -->
                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipSevereUse" icon="HelpCircleIcon" size="16" /> {{ $t('Severe Use')}}
                        </span>
                        <b-form-checkbox id="check-severe-use" v-model="alarmSevere.val" @change="toogleAlarm(SEVERE_USE_ALARM_NAME)" name="alarm-severeuse" class="mr-0" switch inline />
                    </div>

                    <!-- Iddle Use -->
                    <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipIddleUse" icon="HelpCircleIcon" size="16" /> {{ $t('Iddle Use')}}
                        </span>
                        <b-form-checkbox id="check-iddle-use" v-model="alarmIddle.val" @change="toogleAlarm(IDDLE_USE_ALARM_NAME)" name="alarm-iddleuse" class="mr-0" switch inline />
                    </div>

                    <!-- Long Time without connection -->
                    <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipLongTime" icon="HelpCircleIcon" size="16" /> {{ $t('Long Time Without Connection')}}
                        </span>
                        <b-form-checkbox id="check-long-time" v-model="alarmLongTime.val" @change="toogleAlarm(LONG_TIME_ALARM_NAME)" name="alarm-long-time" class="mr-0" switch inline />
                    </div>

                    <!-- Over Speed -->
                    <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipOverSpeed" icon="HelpCircleIcon" size="16" /> {{ $t('Over Speed')}}:
                            <strong v-if="alarmOverSpeed.speed > 0 && alarmOverSpeed.val">{{ alarmOverSpeed.speed }} Km/h</strong>
                        </span>
                        <b-form-checkbox id="check-over-speed" v-model="alarmOverSpeed.val" @change="toogleAlarm(OVER_SPEED_ALARM_NAME)" name="alarm-overspeed" class="mr-0" switch inline />
                    </div>

                    <!-- High Fuel Consumption -->
                    <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipHighFuelConsumption" icon="HelpCircleIcon" size="16" /> {{ $t('High Fuel Consumption')}}:
                            <strong v-if="alarmHighFuelConsumption.consumption > 0 && alarmHighFuelConsumption.val">{{ alarmHighFuelConsumption.consumption }} L/h</strong>
                        </span>
                        <b-form-checkbox id="check-high-fuel-consumption" v-model="alarmHighFuelConsumption.val" @change="toogleAlarm(HIGH_FUEL_CONSUMPTION_ALARM_NAME)" name="alarm-highfuelconsumption" class="mr-0" switch inline />
                    </div>

                    <!-- Low Fuel -->
                    <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipLowFuel" icon="HelpCircleIcon" size="16" /> {{ $t('Low Fuel')}}:
                            <strong v-if="alarmLowFuel.fuel > 0 && alarmLowFuel.val">{{ alarmLowFuel.fuel }} %</strong>
                        </span>
                        <b-form-checkbox id="check-low-fuel" v-model=" alarmLowFuel.val" @change="toogleAlarm(LOW_FUEL_ALARM_NAME)" name="alarm-lowfuel" class="mr-0" switch inline />
                    </div>

                </vue-perfect-scrollbar>

            </b-card>
        </b-col>
    </b-row>

    <!-- Section Modals for create alarms -->
    <!--Modal Create Over Speed-->
    <b-modal ref="modal-create-over-speed" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
        <div class="mx-3">
            <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter the maximum speed in km/h') }}</p>
            <b-form-input placeholder="Km/h" v-model="maxSpeed" />
            <div class="d-flex justify-content-between align-items-center mt-1 ">
                <b-button variant="danger" @click="cancelOverSpeed()">Cancelar</b-button>
                <b-button variant="success" @click="validateOverSpeed()">Confirmar</b-button>
            </div>
        </div>
    </b-modal>

    <!--Modal Create High Fuel Consumption-->
    <b-modal ref="modal-create-high-fuel-consumption" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
        <div class="mx-3">
            <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter consumption in L/h') }}</p>
            <b-form-input placeholder="L/h" v-model="consumption" />
            <div class="d-flex justify-content-between align-items-center mt-1 ">
                <b-button variant="danger" @click="cancelHighFuelConsumption()">Cancelar</b-button>
                <b-button variant="success" @click="validateHighFuelConsumption()">Confirmar</b-button>
            </div>
        </div>
    </b-modal>

    <!--Modal Create Low Fuel-->
    <b-modal ref="modal-create-low-fuel" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
        <div class="mx-3">
            <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter the amount in %') }}</p>
            <b-form-input placeholder="%" v-model="fuel" />
            <div class="d-flex justify-content-between align-items-center mt-1 ">
                <b-button variant="danger" @click="cancelLowFuel()">Cancelar</b-button>
                <b-button variant="success" @click="validateLowFuel()">Confirmar</b-button>
            </div>
        </div>
    </b-modal>

</section>
</template>

<script>
//Imports
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import alarmsStoreModule from './alarmsStoreModule'

import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    VBTooltip,
    BFormCheckbox,
    BModal,
    BFormInput,
    BFormGroup,
    BTh,
    BTr,
    BPagination,
    BBadge,
    BAvatar,
    BFormSelect
} from 'bootstrap-vue'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import Multiselect from 'vue-multiselect'

export default {
    directives: {

        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BFormCheckbox,
        BModal,
        BFormInput,
        BFormGroup,
        BTh,
        BTr,
        BBadge,
        BPagination,
        BAvatar,
        BFormSelect,
        VuePerfectScrollbar,
        flatPickr,
        vSelect,
        Multiselect
    },

    data() {
        return {
            //Title Tooltips form alarms titleTootipBtnCloseFilter
            titleTootipMove: this.$t('Enable to be notified when the vehicle starts moving'),
            titleTootipWeekendMove: this.$t('Enable to be notified when the vehicle starts moving on weekends only'),
            titleTootipIgnition: this.$t('Enable to be notified when vehicle is on'),
            titleTootipSevereUse: this.$t('Enable to be notified when vehicle is in severe use'),
            titleTootipIddleUse: this.$t('Enable to be notified when vehicle is in idle use'),
            titleTootipOverSpeed: this.$t('Enable to be warned when the vehicle exceeds the chosen speed'),
            titleTootipHighFuelConsumption: this.$t('Enable to be notified when the vehicle exceeds the chosen consumption'),
            titleTootipLowFuel: this.$t('Enable to be notified when the vehicle is below the informed limit'),
            titleTootipLongTime: this.$t('Enable to be notified when the asset has not communicated for a long time'),
            titleTootipBtnFilter: this.$t('Open Filters'),
            titleTootipBtnCloseFilter: this.$t('Close Filters'),
            placeholderSelect: this.$t('Pick some'),

            currentPage: 1,
            showFilters: false,

            alarmMove: {
                id: '',
                val: false
            },
            alarmWeekendMove: {
                id: '',
                val: false
            },
            alarmIgnition: {
                id: '',
                val: false
            },
            alarmSevere: {
                id: '',
                val: false
            },
            alarmIddle: {
                id: '',
                val: false
            },
            alarmLongTime: {
                id: '',
                val: false
            },
            alarmOverSpeed: {
                id: '',
                val: false,
                speed: 0
            },
            alarmHighFuelConsumption: {
                id: '',
                val: false,
                consumption: 0
            },
            alarmLowFuel: {
                id: '',
                val: false,
                fuel: 0
            },

            maxSpeed: '',
            fuel: '',
            consumption: '',

            itens: [],
            totalItems: 0,
            from: 0,
            to: 0,

            dateStart: '',
            dateEnd: '',
            chosenAlert: '',
            alertsOptions: [{
                    text: 'Ignição Ligada',
                    value: 1
                },
                {
                    text: 'Uso Severo',
                    value: 14
                },
                {
                    text: 'Uso Ocioso',
                    value: 15
                },
            ],

            options: [],

            asset: {},

            showSettings: false,
            titleTootipBtnSettings: this.$t('Show alarm settings'),

            colLeftMd: '12',



        }
    },
    created() {
        this.fetchAlarms()
        this.fetchTypeAlerts()

    },
    mounted() {
        this.fetchAlerts()
        this.fetchAsset()
    },
    watch: {

        currentPage() {
            this.fetchAlerts()
        }
    },
    methods: {

        showRightCol(){
            if(this.showSettings){
                this.colLeftMd = '12'
                this.showSettings = false
            }
            else {
                this.colLeftMd = '7'
                this.showSettings = true
            }

            
        },

        resolveVariant(typeId) {
            //if (typeId == 1) return 'warning'
            return 'warning'
        },

        fetchTypeAlerts() {
            store
                .dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/fetchTypeAlerts`)
                .then(response => {
                    this.options = response.data.data

                })
                .catch(() => {
                    console.log('erro')
                })
        },

        fetchAsset() {
            store
                .dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/fetchAsset`, {
                    id: this.myId,
                })
                .then(response => {
                    this.asset = response.data

                })
                .catch(() => {
                    console.log('erro')
                })
        },

        formateDate(date) {
            const newDate = new Date(date).toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            })
            //const dateFormated = `${newDate[0]}:${newDate[1]}`

            return newDate
        },

        setHour(date) {
            var dt = new Date(date).toISOString();
            //var newDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 0);
            const newDate = `${dt.split('T')[0]}T23:59:59.${dt.split('.')[1]}`
            console.log('DATA final ', newDate)
            return newDate
        },

        alertToIds(chosenAlert) {
            var ids = []

            chosenAlert.map(item => {
                ids.push(item.id)
            })

            return ids
        },

        fetchAlerts() {
            const start = this.dateStart ? new Date(this.dateStart).toISOString() : ''
            const end = this.dateEnd ? this.setHour(this.dateEnd) : ''
            const alertIds = this.chosenAlert.length > 0 ? this.alertToIds(this.chosenAlert) : ''

            console.log('Valor do select: ', this.chosenAlert)

            store
                .dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/fetchAlerts`, {
                    q: '',
                    page: this.currentPage,
                    assetId: this.myId,
                    alertIds: alertIds,
                    start: start,
                    end: end

                })
                .then(response => {
                    this.itens = this.responseToItem(response.data.data)
                    this.totalItems = response.data.totalItems

                    const localItemsCount = this.itens ? response.data.data.length : 0
                    const perPage = 10
                    this.from = perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0)
                    this.to = perPage * (this.currentPage - 1) + localItemsCount

                })
                .catch((e) => {
                    console.log('erro', e)
                })
        },

        showModalOverSpeed() {
            this.$refs['modal-create-over-speed'].show()
        },
        hideModalOverSpeed() {
            this.$refs['modal-create-over-speed'].hide()
        },
        cancelOverSpeed() {
            this.maxSpeed = ''
            this.alarmOverSpeed.speed = 0
            this.alarmOverSpeed.val = false
            this.hideModalOverSpeed()

        },
        validateOverSpeed() {
            if (this.maxSpeed && this.maxSpeed > 0) {
                this.alarmOverSpeed.speed = this.maxSpeed
                this.createAlarmOverSpeed()
                this.hideModalOverSpeed()
            } else {
                this.maxSpeed = ''
                this.alarmOverSpeed.speed = 0
                this.alarmOverSpeed.val = false
                this.hideModalOverSpeed()
                this.msgDialog(this.$t('Enter a valid speed'), 'error')
            }
        },

        showModalHighFuelConsumption() {
            this.$refs['modal-create-high-fuel-consumption'].show()
        },
        hideModalHighFuelConsumption() {
            this.$refs['modal-create-high-fuel-consumption'].hide()
        },
        cancelHighFuelConsumption() {
            this.consumption = ''
            this.alarmHighFuelConsumption.consumption = 0
            this.alarmHighFuelConsumption.val = false
            this.hideModalHighFuelConsumption()

        },
        validateHighFuelConsumption() {
            if (this.consumption && this.consumption > 0) {
                this.alarmHighFuelConsumption.consumption = this.consumption
                this.createAlarmHighConsumption()
                this.hideModalHighFuelConsumption()
            } else {
                this.consumption = ''
                this.alarmHighFuelConsumption.consumption = 0
                this.alarmHighFuelConsumption.val = false
                this.hideModalHighFuelConsumption()
                this.msgDialog(this.$t('Enter a valid consumption'), 'error')
            }
        },

        showModalLowFuel() {
            this.$refs['modal-create-low-fuel'].show()
        },
        hideModalLowFuel() {
            this.$refs['modal-create-low-fuel'].hide()
        },
        cancelLowFuel() {
            this.fuel = ''
            this.alarmLowFuel.fuel = 0
            this.alarmLowFuel.val = false
            this.hideModalLowFuel()

        },
        validateLowFuel() {
            if (this.fuel && this.fuel > 0) {
                this.alarmLowFuel.speed = this.fuel
                this.createAlarmLowFuel()
                this.hideModalLowFuel()
            } else {
                this.fuel = ''
                this.alarmLowFuel.fuel = 0
                this.alarmLowFuel.val = false
                this.hideModalLowFuel()
                this.msgDialog(this.$t('Enter a valid %'), 'error')
            }
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },

        fetchAlarms() {

            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/fetchAlarms`, {
                    //id: router.currentRoute.params.id
                    id: this.myId
                })
                .then(res => {

                    if (res.data.data.length > 0) {
                        let alarms = res.data.data
                        alarms.map(alarm => {
                            if (alarm.name == this.MOVE_ALARM_NAME) {
                                this.alarmMove.val = true;
                                this.alarmMove.id = alarm.id
                            } else if (alarm.name == this.WEEKEND_MOVE_ALARM_NAME) {
                                this.alarmWeekendMove.val = true;
                                this.alarmWeekendMove.id = alarm.id
                            } else if (alarm.name == this.IGNITION_ALARM_NAME) {
                                this.alarmIgnition.val = true;
                                this.alarmIgnition.id = alarm.id
                            } else if (alarm.name == this.SEVERE_USE_ALARM_NAME) {
                                this.alarmSevere.val = true;
                                this.alarmSevere.id = alarm.id
                            } else if (alarm.name == this.LONG_TIME_ALARM_NAME) {
                                this.alarmLongTime.val = true;
                                this.alarmLongTime.id = alarm.id
                            } else if (alarm.name == this.IDDLE_USE_ALARM_NAME) {
                                this.alarmIddle.val = true;
                                this.alarmIddle.id = alarm.id
                            } else if (alarm.name == this.OVER_SPEED_ALARM_NAME) {
                                this.alarmOverSpeed.val = true;
                                this.alarmOverSpeed.speed = alarm.value;
                                this.alarmOverSpeed.id = alarm.id
                            } else if (alarm.name == this.HIGH_FUEL_CONSUMPTION_ALARM_NAME) {
                                this.alarmHighFuelConsumption.val = true;
                                this.alarmHighFuelConsumption.consumption = alarm.value;
                                this.alarmHighFuelConsumption.id = alarm.id
                            } else if (alarm.name == this.LOW_FUEL_ALARM_NAME) {
                                this.alarmLowFuel.val = true;
                                this.alarmLowFuel.fuel = alarm.value;
                                this.alarmLowFuel.id = alarm.id
                            }

                        })
                    }

                })
                .catch(error => {

                })
        },

        toogleAlarm(typeAlarm) {
            console.log('ENTROU NO TOOGLE', typeAlarm)
            switch (typeAlarm) {
                case this.MOVE_ALARM_NAME:
                    //this.alarmMove.model ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
                    this.alarmMove.val ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
                    break;
                case this.WEEKEND_MOVE_ALARM_NAME:
                    this.alarmWeekendMove.val ? this.createAlarmWeekendMove() : this.deleteAlarm(this.alarmWeekendMove.id, this.WEEKEND_MOVE_ALARM_NAME)
                    break;
                case this.IGNITION_ALARM_NAME:
                    this.alarmIgnition.val ? this.createAlarmIgnition() : this.deleteAlarm(this.alarmIgnition.id, this.IGNITION_ALARM_NAME)
                    break;
                case this.SEVERE_USE_ALARM_NAME:
                    this.alarmSevere.val ? this.createAlarmSevere() : this.deleteAlarm(this.alarmSevere.id, this.SEVERE_USE_ALARM_NAME)
                    break;
                case this.IDDLE_USE_ALARM_NAME:
                    this.alarmIddle.val ? this.createAlarmIddle() : this.deleteAlarm(this.alarmIddle.id, this.IDDLE_USE_ALARM_NAME)
                    break;
                case this.LONG_TIME_ALARM_NAME:
                    this.alarmLongTime.val ? this.createAlarmLongTime() : this.deleteAlarm(this.alarmLongTime.id, this.LONG_TIME_ALARM_NAME)
                    break;
                case this.OVER_SPEED_ALARM_NAME:
                    this.alarmOverSpeed.val ? this.showModalOverSpeed() : this.deleteAlarm(this.alarmOverSpeed.id, this.OVER_SPEED_ALARM_NAME)
                    break;
                case this.HIGH_FUEL_CONSUMPTION_ALARM_NAME:
                    this.alarmHighFuelConsumption.val ? this.showModalHighFuelConsumption() : this.deleteAlarm(this.alarmHighFuelConsumption.id, this.HIGH_FUEL_CONSUMPTION_ALARM_NAME)
                    break;
                case this.LOW_FUEL_ALARM_NAME:
                    this.alarmLowFuel.val ? this.showModalLowFuel() : this.deleteAlarm(this.alarmLowFuel.id, this.LOW_FUEL_ALARM_NAME)
                    break;
                default:
                    console.log('teste')
            }
        },

        createAlarmLongTime() {

            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addLongTimeAlarm`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')

                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmMove.val = !this.alarmMove.val
                    this.fetchAlarms()
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },

        //Create alarms
        createAlarmMove() {

            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addMoveAlarm`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')

                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmMove.val = !this.alarmMove.val
                    this.fetchAlarms()
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmWeekendMove() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addAlarmWeekendMove`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmWeekendMove.val = !this.alarmWeekendMove.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmIgnition() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addIgnitionAlarm`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmIgnition.val = !this.alarmIgnition.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmSevere() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addSevereAlarm`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmSevere.val = !this.alarmSevere.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmIddle() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addIddleAlarm`, {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmIddle.val = !this.alarmIddle.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmOverSpeed() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addOverSpeedAlarm`, {
                    assetId: this.myId,
                    value: this.alarmOverSpeed.speed
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmOverSpeed.val = !this.alarmOverSpeed.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmHighConsumption() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addHighConsumptionAlarm`, {
                    assetId: this.myId,
                    value: this.alarmHighFuelConsumption.consumption
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmHighFuelConsumption.val = !this.alarmHighFuelConsumption.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmLowFuel() {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addLowFuelAlarm`, {
                    assetId: this.myId,
                    value: this.alarmLowFuel.fuel
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmLowFuel.val = !this.alarmLowFuel.val
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },

        //delete alarms
        deleteAlarm(id, typeAlarm) {
            store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/deleteAlarm`, {
                    id: id
                })
                .then(response => {
                    if (typeAlarm == this.OVER_SPEED_ALARM_NAME) {
                        this.alarmOverSpeed.val = false;
                        this.alarmOverSpeed.speed = 0;
                        this.maxSpeed = ''
                    } else if (typeAlarm == this.HIGH_FUEL_CONSUMPTION_ALARM_NAME) {
                        this.alarmHighFuelConsumption.val = false;
                        this.alarmHighFuelConsumption.consumption = 0;
                        this.consumption = ''
                    } else if (typeAlarm == this.LOW_FUEL_ALARM_NAME) {
                        this.alarmLowFuel.val = false;
                        this.alarmLowFuel.fuel = 0;
                        this.fuel = ''
                    }
                    this.msgDialog(this.$t('You disabled the alarm!'), 'warning')
                })
                .catch(error => {
                    if (typeAlarm == this.OVER_SPEED_ALARM_NAME) {
                        this.alarmOverSpeed.val = true;

                    } else if (typeAlarm == this.HIGH_FUEL_CONSUMPTION_ALARM_NAME) {
                        this.alarmHighFuelConsumption.val = true;

                    } else if (typeAlarm == this.LOW_FUEL_ALARM_NAME) {
                        this.alarmLowFuel.val = true;

                    }
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to disable'), 'error')
                })

        },

        responseToItem(alarms) {
            // this gives an object with dates as keys
            const groups = alarms.reduce((groups, game) => {
                const date = new Date(game.datetime).toLocaleDateString("pt-BR");
                //const date = game.datetime.split('T')[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            // Edit: to add it in the array format instead
            const groupArrays = Object.keys(groups).map((date) => {
                const dayWeek = this.getDayWeek(date)
                return {
                    date,
                    dayWeek,
                    hours: groups[date]
                };
            });

            console.log('TESTE PARA CONVERTER', groupArrays)
            return groupArrays
        },

        getDayWeek(dt) {
            const month = dt.split('/')[1]
            const day = dt.split('/')[0]
            const year = dt.split('/')[2]

            const week = new Date(`${month}/${day}/${year}`).getDay()

            switch (week) {
                case 0:
                    return this.$t('Sunday')
                case 1:
                    return this.$t('Monday')
                case 2:
                    return this.$t('Tuesday')
                case 3:
                    return this.$t('Wednesday')
                case 4:
                    return this.$t('Thursday')
                case 5:
                    return this.$t('Friday')
                case 6:
                    return this.$t('Saturday')
                default:
                    // code block
            }
        }
    },
    setup() {
        const myId = router.currentRoute.params.id
        const ALARMS_APP_STORE_MODULE_NAME = 'app-alarms'

        const MOVE_ALARM_NAME = "Move Alarm"
        const WEEKEND_MOVE_ALARM_NAME = "Weekend Move Alarm"
        const IGNITION_ALARM_NAME = "Ignition Alarm"
        const SEVERE_USE_ALARM_NAME = "Severe use Alarm"
        const IDDLE_USE_ALARM_NAME = "Iddle use Alarm"
        const LOW_FUEL_ALARM_NAME = "Low Fuel Alarm"
        const HIGH_FUEL_CONSUMPTION_ALARM_NAME = "High Fuel Consumption Alarm"
        const OVER_SPEED_ALARM_NAME = "Overspeed Alarm"
        const LONG_TIME_ALARM_NAME = "Long time without connection"

        

        // Register module
        if (!store.hasModule(ALARMS_APP_STORE_MODULE_NAME)) store.registerModule(ALARMS_APP_STORE_MODULE_NAME, alarmsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ALARMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ALARMS_APP_STORE_MODULE_NAME)
        })

        // Perfect Scrollbar
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            myId,
            ALARMS_APP_STORE_MODULE_NAME,

            //consts for names alarms
            MOVE_ALARM_NAME,
            WEEKEND_MOVE_ALARM_NAME,
            IGNITION_ALARM_NAME,
            SEVERE_USE_ALARM_NAME,
            IDDLE_USE_ALARM_NAME,
            OVER_SPEED_ALARM_NAME,
            LOW_FUEL_ALARM_NAME,
            HIGH_FUEL_CONSUMPTION_ALARM_NAME,
            LONG_TIME_ALARM_NAME,

           

            perfectScrollbarSettings
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.alarm-section {
    //padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;
}

.event-weekendmove {
    background-color: #ea5455 !important;
}

.day-table {
    background-color: #f3f2f7;
}

.style-dark {
    background-color: #d3d6dc !important;
}

.ps-customizer-area {
    height: calc(100% - 83px)
}

.per-page-selector {
    width: 90px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .8s ease;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

    {
    opacity: 0
}
</style>


